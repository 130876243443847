<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-12 11:58:47
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-11 10:18:12
-->
<template>
  <div id="Home">
    <el-container class="homeContainer">
      <el-aside width="auto" style="background:#384661;">
        <el-scrollbar style="height:100%;" :native="false" wrapStyle wrapClass viewClass viewStyle :noresize="false" tag="div">
          <div style="height:100%">
            <div class="logoColl">
              <div :class="['logoColl_img',isCollapse?'colllogoact':'']">
                <img src="../assets/image/logo.png" alt="">
              </div>
              <i :class="['el-icon-s-fold','isCollapseBtn',isCollapse?'CollapseBtnRate':'']" @click="collHandler"></i>
            </div>
            <el-menu :default-active="activeUrl" :unique-opened="true" :router="true" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" background-color="#384661" text-color="#DCDFE6" active-text-color="#409EFF" :collapse-transition="true">
              <template v-for="(item,index) in routerList">
                <el-submenu :index="item.path" v-if="item.children" :key="index">
                  <template slot="title">
                    <i style="width: 18px;text-align: center;color:#DCDFE6;" :class="item.icon"></i>
                    <span slot="title" style="font-weight:800;">{{item.name}}</span>
                  </template>
                  <el-menu-item v-for="(child,index) in item.children" :index="child.path" :key="index">{{child.name}}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else :index="item.path" :key="index">
                  <i :class="item.icon" style="width: 18px;text-align: center;color:#DCDFE6;"></i>
                  <span style="font-weight:800;">{{item.name}}</span>
                </el-menu-item>
              </template>
            </el-menu>
          </div>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header>
          <div class="user_photo_box">
            <el-dropdown @command="command">
              <el-avatar shape="square" :size="50" fit="contain" :src="logoUrl"></el-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="out">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      activeUrl: "/homePage",
      isCollapse: false,
      logoUrl:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      routerList: [
        // {
        //   name: "项目数据",
        //   path: "/homePage",
        //   icon: "el-icon-s-home"
        // },
      ],
    };
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    collHandler() {
      this.isCollapse = !this.isCollapse;
    },
    command(comd) {
      console.log(comd);
      switch (comd) {
        case "out":
            window.sessionStorage.userInfo = null;
            this.$router.push({
                name: "Login",
            });
      }
    },
  },
  mounted() {
    this.activeUrl = this.$route.path;
  },
  created() {
    let userName = window.sessionStorage.userName;
    console.log(userName);
    if(true){
        let arr = [
            {
                name: "设备录入",
                path: "/deviceInput",
                icon: "el-icon-s-home"
            },
        ]
        this.routerList = [...this.routerList, ...arr];
        return;
    }
    if (userName) {
      if (userName == "kaiyitest") {
        let arr = [
          {
            name: "天津举报",
            path: "/tianjinjb",
            icon: "el-icon-platform-eleme",
          },
          {
            name: "截帧任务",
            path: "/cutPhoto",
            icon: "el-icon-video-camera-solid",
            children: [
              { name: "截帧列表", path: "/cutPhoto/cutPhoto", icon: "" },
              // {
              //   name: "表格样例",
              //   path: "/goodsList/goodsList",
              //   icon: "el-icon-platform-eleme"
              // },
              {
                name: "截帧统计图",
                path: "/cartogram/index",
                icon: "el-icon-platform-eleme",
              },
              {
                name: "状态码分析",
                path: "/statusCode/statusCode",
                icon: "el-icon-platform-eleme",
              },
              {
                name: "报表分析",
                path: "/statement/statement",
                icon: "el-icon-platform-eleme",
              },
            ],
          },
          // {
          //   name: "地图测试",
          //   path: "/map",
          //   icon: "el-icon-s-help"
          // }
        ];
        this.routerList = [...this.routerList, ...arr];
      } else if (userName == "tjvideorep") {
        let arr = [
          {
            name: "天津举报",
            path: "/tianjinjb",

            icon: "el-icon-platform-eleme",
          },
        ];
        this.routerList = [...this.routerList, ...arr];
      } else if (userName == "photocut") {
        let arr = [
          {
            name: "截帧任务",
            path: "/cutPhoto",
            icon: "el-icon-video-camera-solid",
            children: [
              { name: "截帧列表", path: "/cutPhoto/cutPhoto", icon: "" },
              {
                name: "截帧统计图",
                path: "/cartogram/index",
                icon: "el-icon-platform-eleme",
              },
              {
                name: "状态码分析",
                path: "/statusCode/statusCode",
                icon: "el-icon-platform-eleme",
              },
              {
                name: "报表分析",
                path: "/statement/statement",
                icon: "el-icon-platform-eleme",
              },
            ],
          },
        ];
        this.routerList = [...this.routerList, ...arr];
      }
    } else {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
<style lang="scss" scoped>
#Home {
  height: 100%;
  background: #f2f6fc;
  .homeContainer ::v-deep {
    height: 100%;
    .logoColl {
      height: 60px;
      background: #fff;
      position: relative;
      background-color: #26344f;
      border-bottom: 1px solid #fff;
      .logoColl_img {
        width: 150px;
        height: 60px;
        display: inline-block;
        text-align: center;
        transition: all ease-in 0.3s;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .colllogoact {
        width: 0px;
      }
      .isCollapseBtn {
        font-size: 30px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        margin-top: 15px;
        right: 20px;
        color: #fff;
        // transform: translateY(-50%);
        transition: all ease-in 0.3s;
        &:hover {
          color: #409eff;
        }
      }
      .CollapseBtnRate {
        transform: rotateZ(180deg);
      }
    }

    //解决导航右侧超出1px
    .el-menu {
      border-right: 1px solid #384661;
    }
    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #fff;
    }
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .el-submenu.is-active .el-submenu__title {
      background-color: #26344f !important;
      font-weight: bold;
    }
    //导航激活时的背景颜色
    .el-menu-item.is-active {
      background-color: #26344f !important;
      color: #fff !important;
      font-weight: bold;
      i {
        color: #fff !important;
      }
    }
  }
  .el-header {
    background-color: #384661;
    color: #fff;
    border-left: 1px solid #fff;
    text-align: right;
    .user_photo_box {
      height: 50px;
      width: 50px;
      background: #26344f;
      margin-top: 5px;
      display: inline-block;
    }
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
